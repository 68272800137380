.card-info{
    max-width: 85%;
    min-width: 50%;
    padding: 2em;
    box-shadow: none;
    @media (min-width: 992px){
        width: 900px;
    }
    @media (max-width: 992px){
        width: 700px;
    }
    @media (max-width: 768px){
        width: 500px;
    }
    @media (max-width: 576px){
        width: 85%;
    }
    &--turn{
        width: 100%;
        box-shadow: none;
        border: 0;
        margin: 0 !important;
    }
    &--turn2{
        padding: 0;
        margin: 0;
        @media (max-width: 768px){
            min-width: 100%;
        }
    }
}

.item--turn {
    background-color: $color-primary;
    h2 {
        color: #fff !important;
    }
    p {
        color: #fff !important;
    }
}

.finish-text {
    color: #fff !important;
    background-color: #26639D;
    padding: 10px;
    width: 75%;
    text-align: center;
    margin: 0 auto 20px;
    border-radius: 5px;
    line-height: 35px;
    font-size: 1.8em !important;
}
.search-input{
    border: 1px solid;
    border-color: #EAEAEA;
}
.disabled-input {
    background-color: #f8f8f8;
}
.captcha-container{
    display: flex;
    justify-content: center;
    background-color: transparent;
}
.card {
    background-color: transparent !important;
    &--full-width {
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    &--full-height {
        margin-bottom: 0;
        margin-top: 0;
        height: auto;
    }

    &--fixed {
        position: fixed;
        top: 50px;
        bottom: 97px;
        transition: 0.3s;
        @media (min-width: 768px) {
            bottom: 50px;
        }
    }
    &--map {
        &__container{
            & > div {
                height: 100%;
            }
        }
        &__map {
            min-height: 300px;
            height: 100%;
        }
    }
}
.top-bar{
    width: 100%;
    position: relative;
    top: 0px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.3em;
    height: 50px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.07);
    z-index:999;
    &--center{
        justify-content: center;
    }
    &__title{
        font-size: 1rem;
        margin:0;
    }
    &__align{
        width: 2rem;
    }
}

.title-bar{
    width: 100%;
    position: relative;
    top: 0px;
    background-color: #26639D;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.3em;
    height: 80px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.07);
    z-index:999;
    &--center{
        justify-content: center;
    }
    &__title{
        font-size: 1.2rem;
        margin:0;
        color: #fff;
        font-weight: 700;
    }
    &__align{
        width: 2rem;
    }
}

.menu-bar{
    width: 100%;
    position: fixed;
    bottom: 0px;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.07);
    display: flex;
    justify-content: center;
    z-index: 999;
    &__container-items{
        display: flex;
        align-items: center;
        width:100%;
        padding: 0 3em;
        justify-content: space-around;
        height: 50px;
        button {
            border: 0;
            background-color: transparent;
        }
        @media (min-width: 768px){
            width:35%;
        }
    }
}
.fa{
    color: #d0c9d6;
}
.fa:hover{
    color: #26639D;
}
.fa-user-circle{
    font-size: 15px;
}
.form__input{
    color: $inputColor;
    background-color: transparent;
    width: 100%;
    margin: 0 !important;
    padding: 5px;
    height: 15px;
}
.form__input--text{
    border-bottom: 1px solid #D4D5D5 !important;
}
.custom-radio-selector{
    margin: 0px 10px;
    border-radius: 4px;
	.info{
		border: 1px solid #eae9e9;
		border-top: none;
		border-radius: 4px;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		text-align: left;
		display: none;
        padding: 1em;
		font-size: 13px;
		color: #444;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
        flex-wrap: nowrap;
		.info-left{
			min-width: 30%;
			max-width: 50%;
			display: flex;
		}
		.info-left,
		.info-right{
            margin-top:0.5em;
			display: flex;
            vertical-align: top;
		}
		.info-right{
            color: $color-primary;
            font-weight: bold;
			text-align: right;
		}
		.info-row{
			display: flex;
			flex-direction: row;
			align-content: stretch;
			justify-content: space-between;
		}
	}
    .inputGroup{
        background-color: #fff;
        display: block;
        margin: 10px 0;
        position: relative;
        &-hidden {
            display: none !important;
        }
        &:first-of-type {
            clear: both;
        }
        label{
            transition: color 200ms ease-in;
            display: block;
            &:after{
                width: 26px;
                height: 26px;
                content: '';
                border: 1px solid #D1D7DC;
                background-color: #fff;
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
                background-repeat: no-repeat;
                background-position: 2px 3px;
                background-size: 109%;
                border-radius: 50%;
                z-index: 2;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                transition: all 200ms ease-in;
            }
        }
        input:checked ~ .info{
            display: block;
        }
        input ~ .viewSelectBranch__branch-title {
            transition: background-color 200ms ease-in;
        }
        input:checked ~ .viewSelectBranch__branch-title {
            border: 1px solid #26639D;
            border-radius: 4px;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
            transition: all .3s ease;
            transition-property: border, background-color;
            & > label {
                color: #26639D;
                font-weight: bold;
                transition: all .3s ease;
                transition-property: color, font-weight;

                &:before {
                    transform: translate(-50%, -50%) scale3d(56, 56, 1);
                    opacity: 1;
                }

                &:after {
                    background-color: #26639D;
                    border-color: #fff;
                }
            }
        }
        input{
            width: 32px;
            height: 32px;
            order: 1;
            z-index: 2;
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            visibility: hidden;
        }
    }
}
.app-select{
    background: transparent;
    border: none;
    font-size: 16px;
    height: 30px;
    padding: 5px;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
}
.box-select{
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 7px;
}
.box-select::after{
    content: '\f0dd';
    font-family: 'FontAwesome' !important;
    font-size: 16px;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 0;
    padding: 0 1.5em;
    cursor: pointer;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
  }
  /* Transition */
  .box-select:hover::after{
    color: #26639D;
  }


.alert{
   
    &__container{
        animation: float .7s;
        animation-fill-mode: forwards;
        position: fixed;
        display: flex;
        transform: translateX(-50%);
        left: 50%;
        top: 10px;
        z-index: 9999;
        width: 85vw;
        max-width: 550px;
        background-color: #FEFEFF;
        box-shadow: 0 0 5px #CCC;
        border-radius: 5px;
        padding: 15px 5px;
        align-items: center;
    }
    &__title{
        &-container{
            width: 75%;
        }
        font-size: .9rem;
        color: #8e94a0;
        margin-bottom: 3px;
        margin-top: 5px !important;
        left: 0;
        margin-left: 10px;
    }
    &__button{
        &-container{
            right: 0;
            position: absolute;
            margin-right: 10px;
        }

        background-color: #fff;
        border: 1px solid #8e94a0;
        border-radius: 5px;
        font-size: .85rem;
        padding: 4px 10px;
        color: #8e94a0;
    }
}

.hideTurn {
    display: none;
}


@keyframes float {
    0% {
      opacity: 0;
    }
    100% {
      top: 20px;  
      opacity: 1;
    }
  }

  .language-selector-dropdown {
    position: absolute;
    bottom: 130%;
    background-color: #fff;
    text-align: left;
    left: 0;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.07);
  }

.language-selector-dropdown li {
    margin: 1rem;
} 

.language-selector-dropdown li:hover {
    color: $color-primary;
    transform: scale(1.05);
} 
  .language-selector-container {
    position: relative;
  }


.priority-msg {
    width: fit-content;
    margin-inline: auto;
    margin-bottom: 0.5rem;
    &_icon {
        color: $color-primary;
        font-size: 1.5rem;
    }
    &_main {
        font-size: 1.5rem!important;
    }
}

.no-border-bottom {
    border-bottom: none;
}